
//import { appResources } from "./authConfig";
import MsalHelper from "./MsalHelper";
import history from "./history"
import { UniqueId } from "./Utility";
import { MessageHandler } from "./MessageHandler";


const noAccessResponse = new RegExp(["undefined", "null", '"status":( ?)401', '"StatusCode":( ?)401', '"status":( ?)403', '"StatusCode":( ?)403'].join('|'), 'gi');
const notfoundResponse = new RegExp(['"StatusCode":( ?)404', '"status":( ?)404'].join('|'), 'gi');
const errorResponse = new RegExp(["undefined", "null", '"StatusCode":( ?)500', '"StatusCode":( ?)502','"StatusCode":( ?)501','"StatusCode":( ?)503'].join('|'), 'gi');
export async function callApi(appResources, apiEndpoint, abortSignal, method = "GET", postData, errorRedirect=true ) {
    const _history = history;  
    return MsalHelper.getAccessToken(appResources?.scopes).then((accessToken) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);    
        let sessionid = sessionStorage.getItem("sessionid") ? sessionStorage.getItem("sessionid") : UniqueId("REQ");
        sessionStorage.setItem("sessionid", sessionid);
        headers.append("session-id", sessionid );    
        return getAuthzToken(headers, appResources).then((response) => {
            if (response === "" || noAccessResponse.test(response)) {
                _history.push("/noaccess")
            }
            else if (notfoundResponse.test(response))
            {
                _history.push('/notfound')
            }
            else if (errorResponse.test(response)) {
                _history.push("/error")
            }
            else {
                const authzToken = response;
                sessionStorage.setItem("authzToken", authzToken);
                headers.append("authz", authzToken);
                headers.append("Content-Type", 'application/json');
                
                const options =
                postData ? {
                    method: method,
                    headers: headers,
                    signal: abortSignal,
                    body: JSON.stringify(postData)
                } : {
                    method: method,
                    headers: headers,
                    signal: abortSignal,
                };
                
                return callFetch(appResources.appEndpoint + apiEndpoint, options)
                    .then(response => {
                        if (response === "" || noAccessResponse.test(response)) {
                            MessageHandler("noaccess", errorRedirect)
                            return undefined;
                        }
                        else if (notfoundResponse.test(response)) {
                            MessageHandler("notfound", errorRedirect)
                            return undefined;
                        }
                        else if (errorResponse.test(response)) {
                            MessageHandler("error", errorRedirect)
                            return undefined;
                        }
                        else {
                            if (method !== "GET") {
                                MessageHandler("success", errorRedirect)
                            }
                            return response;
                        }
                    }
                    )
                    .catch(error => {
                        if (options.signal?.aborted) {
                            console.log("The user aborted the request");
                        }
                        else {
                            MessageHandler(response, false)
                            _history.push("/error");
                            console.log(error)
                        }
                        
                    });
            }         
        }).catch(error => console.log(error));
    })
}


async function callFetch(endpoint, options) {
    const _history = history;  
    return fetch(endpoint, options)
        .then(response => {
            if (response.status === 200)
                return response.json()
            else if (response.status === 204)
                return []
            else {
                if (response.status === 404 || response.status === 401) {
                    
                    return '{ "StatusCode": '+response.status+' }'
                }
                return response.text()
            }
        }).catch(error => {
            if (options.signal.aborted) {
                console.log("The user aborted the request");
                throw new Error({ code: 20 });
            }
            else {
                _history.push("/error");
                console.log(error)
            }
        });
}

async function getAuthzToken(headers, appResources) {
    
    const _history = history;
    const authzToken = sessionStorage.getItem("authzToken");
    if (authzToken === undefined || authzToken === null || authzToken === "null" || authzToken ==="") {
        const options = {
            method: "GET",
            headers: headers,
        };        
        return fetch(appResources.appEndpoint + "/authz/authorize", options)
            .then(response => {
                if (response.status === 200)
                    return response.text();
                if (response === "" || noAccessResponse.test(response))
                    _history.push("/noaccess")
                else if (errorResponse.test(response))
                    _history.push("/error")          
            })
            .catch(error => {
                
                console.log(error)
                _history.push("/error");
            });
    }
    else {
        return authzToken;
    }
    
}

export async function getUserPermission(appResources,endpoint) {
    const userPermission = sessionStorage.getItem("userPermission");
    if (userPermission === undefined || userPermission === null || userPermission === "null" || userPermission === "") {
        return callApi(appResources,endpoint).then((response) => {
            //DataStore.setStoreValue(RouteConfig.name, response[0].setting_values);
           if (response) {
               sessionStorage.setItem("userPermission", response[0]?.setting_values)
               sessionStorage.setItem("userFeatures", response[0]?.features? JSON.stringify(response[0]?.features) : undefined)
           }
            return (response??[])[0]?.setting_values;
        })
    }
    else {
        return (userPermission??"").split(",");
    }
}

export async function getUserPreferences(appResources, endpoint) {
        return callApi(appResources, endpoint).then((response) => {
            if (response) {
               return JSON.parse(response[0]?.UserPreference ?? '{}')
            }
            return {};
        })
}



export function getUserPermissionSession() {
    return (sessionStorage.getItem("userPermission") ?? "").split(",");
}

export function getUserFeaturesSession() {
    let features = sessionStorage.getItem("userFeatures");
    return (features && features !== "undefined" ? JSON.parse(features):undefined);
}


//const JTOKEN_START_OBJECT = '{';
//const JTOKEN_END_OBJECT = '}';
//function JsonStreamDecoderNew(chunk, partialItem, level) {
//    let jsonItems = [];
//    let itemStart = 0
//    for (let i = 0; i < chunk.length; i++) {
//        if (chunk[i] === JTOKEN_START_OBJECT) {
//            if (level === 0) {
//                itemStart = i;
//            }
//            level++;
//        }
//        if (chunk[i] === JTOKEN_END_OBJECT) {
//            level--;
//            if (level === 0) {
//                let item = chunk.substring(itemStart, i + 1);
//                if (partialItem) {
//                    item = partialItem + item;
//                    partialItem = '';
//                }
//                jsonItems.push(JSON.parse(item));
//            }
//        }
//    }
//    if (level !== 0) {
//        partialItem = chunk.substring(itemStart);
//    }
//    return { JsonItems: jsonItems, PartialItem: partialItem, Level:level }
//}

