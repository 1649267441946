import React, {  useEffect, useState } from "react";
import { Routes, Route} from "react-router-dom";
import { CustomBrowserRouter } from "./CustomBrowserRouter"
import { PageLayout } from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { ThemeProvider } from '@mui/material/styles'
import PortalTheme from "./PortalTheme"
//import { RouteConfig } from "./Configs1/RouteConfig";
//import { getUserPermission, callApi } from "./fetch";
import { callApi, getUserPermission, getUserPreferences } from "./fetch";
import { AppCustomEvents } from "./AppCode/AppCustomEvents";
import { Store } from "./Store";
import MsalHelper from "./MsalHelper";
import { ProgressCircle } from './components/ProgressCircle';
import { ProgressRefresh } from './components/ProgressRefresh';
import { AlertMessage } from "./components/AlertMessage";
import StateStore from "./StateStore";


function App() {
    const [userPermission, setUserPermission] = useState();
    //const userPermission = undefined;
    const [appReady, setAppReady] = useState();
    const [routeConfig, setRouteConfig] = useState();
    //const [progress, setProgress] = useState();
    const isAuthenticated = useIsAuthenticated();

    const AppStore = new Store("app");
    const stateStore = new StateStore("app");
    const appObject = {
        callApi: callApi,
        appStore: AppStore,
    }

    const appName = AppStore.Get("appCfg.name")
    const RouteConfig = AppStore.Get("routeConfig")
    const ProfileMenuConfig = AppStore.Get("profileMenuConfig");
    let progressBarType = AppStore.Get("appCfg.progressBarType") ?? "circle";
    useEffect(() => {
        let configFolder = AppStore.Get("appCfg.configFolder") ?? "";
        configFolder = configFolder !== "" ? "/" + configFolder + "/" : "/"

        import("./Configs" + configFolder + "ProfileMenuConfig.js")
            .then(config => {
                let profileMenuConfig = config["ProfileMenuConfig"];
                AppStore.Set("profileMenuConfig", [ ...profileMenuConfig??[] ]);
                import("./Configs" + configFolder + "RouteConfig.js")
                    .then(config => {
                        let routeConfig = config["RouteConfig"];
                        AppStore.Set("routeConfig", { ...routeConfig });
                        setRouteConfig(true);                     
                    }).catch(error => {
                        console.log(error)
                    });
            }).catch(error => {
                console.log(error)
            });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        const onAppLoad = async function () {


            //display the app navbar
           
            // display the navision in appnavbar
            const permission = await getUserPermission(AppStore.Get("appCfg.serviceCfg.appResources"), RouteConfig?.userPermission?.dataSource?.url)
            setUserPermission(permission);


            if (RouteConfig.userPreference?.enabled === true) {
                // if user preference is enabled load saved user preference
                let userPreferences = AppStore.Get("userPreferences");
                if (userPreferences === undefined || userPreferences === null || userPreferences === "null" || userPreferences === "") {
                    const getUserPrefUrl = RouteConfig?.userPreference?.dataSource?.find(u => u.name === "getUserPreference").url;
                    if (getUserPrefUrl) {
                        userPreferences = await getUserPreferences(AppStore.Get("appCfg.serviceCfg.appResources"), getUserPrefUrl)
                        AppStore.Set("userPreferences", userPreferences)
                    }
                }
            }

            // load custom appload
            const onAppLoadFn = AppCustomEvents["onAppLoad"];

            
            
            const isonAppLoadFnCompleted = typeof onAppLoadFn === "function" ? await onAppLoadFn.call(appObject) : await false;
            if (isonAppLoadFnCompleted) {
                

            }
            stateStore.Set("progress", { display: false, for: "app" })
            setAppReady(true);
        }
        if (isAuthenticated && !appReady) {
            //setProgress(true);
            stateStore.Set("progress", {display:true, for:"app"})
            AppStore.Set("username", MsalHelper.getName());
            AppStore.Set("useremail", MsalHelper.getEmail());
            AppStore.Set("userid", MsalHelper.getUserID());
            if (routeConfig === true)
                onAppLoad();
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, routeConfig])

    const LoginResources = AppStore.Get("appCfg.serviceCfg.loginResouces")

    return (
        <>
        <CustomBrowserRouter>
            <ThemeProvider theme={PortalTheme}>

                <AuthenticatedTemplate>

                    {appReady === true ?
                            <PageLayout appName={appName} RouteConfig={RouteConfig} ProfileMenuConfig={ProfileMenuConfig }>
                            <Pages permission={userPermission} app={appObject} RouteConfig={RouteConfig} ></Pages>
                        </PageLayout>
                        : ""}
                       
                    <AlertMessage></AlertMessage>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                        <PageLayout appName={appName} LoginResources={LoginResources}>

                        <p>You are not signed in! Please sign in.</p>
                    </PageLayout>
                    </UnauthenticatedTemplate>
                    <ProgressCircle type={progressBarType}></ProgressCircle>
                    <ProgressRefresh type={progressBarType}></ProgressRefresh>
            </ThemeProvider>
        </CustomBrowserRouter>
           </>
    );
}
const Pages = (props) => {
    const unRestricatedRoutes = props.RouteConfig.routes.filter(r => r.restricted !== true).map(r => r["caption"]);
    const routeAllowed = (props.permission ?? []).concat(unRestricatedRoutes);
    //console.log(routeAllowed)
    return (
        <Routes>
            {flattenRoute(props.RouteConfig.routes)
                .filter(route => routeAllowed.some(p => p.toLowerCase() === route.caption.toLowerCase()))
                .map((route, i) => (<Route key={"route" + i} path={route.url} element={<route.component {...route} app={props.app} />}></Route>))}
            
        </Routes > 
        
    )
}



function flattenRoute(data) {
    var result = [];
    function recurse(cur) {
        for (var i = 0, l = cur.length; i < l; i++) {
            if (cur[i]["routes"] !== undefined) {
                result.push({ ...cur[i], subRoute: undefined });
                recurse(cur[i]["routes"])
            }
            else {
                result.push(cur[i]);
            }
        }
        
    }
    
    recurse(data)
    return result;
}

export default App;