export const enumDataType = Object.freeze({
    String: "string",
    Number: "number",
    Date: "date",
    DateTime: "datetime",
    Amount: "amount",
    Boolean: "boolean",
    JSONObject: "json",
    Array: "array"
});

export const enumCtrlType = Object.freeze({
    Label: "label",
    HyperLink: "hyperlink",
    NavLink: "navlink",
    Button: "button",
    Icon: "icon",
    IconButton: "iconbutton",
    AutoComplete: "autocomplete",
    DropDown: "dropdown",
    TextField: "textfield",
    CheckBox: "checkbox",
    Switch: "switch",
    RadioGroup: "radiogroup",
    TextArea: "textarea",
    DateTime: "datetime",
    Date: "date",
    DateRange:"daterange",
    Time: "time",
    label: "label",
    Chip: "chip",
    ButtonBar: "buttonbar",
    UniqueID: "uniqueid"
});

export const enumSortDirection = Object.freeze({
    Asc: "asc",
    Desc: "desc"
});

export const enumConditionalOperator = Object.freeze({
    Equal: "eq",
    NotEqual: "nteq",
    GreaterThan: "grthan",
    GreaterThanOrEqual: "grtharneq",
    LessThan: "lsthan",
    LessThanOrEqual: "lsthanreq",
    In: "in",
    Between:"between"
})


export const enumActions = Object.freeze({

    Add: "add",
    Edit:"edit",
    Delete:"delete",
    Save: "save",
    Search:"search",
    Cancel: "cancel", // close the component with out changing datastore
    Reset:"reset", // reset back to original object from datastore.
    Export:"export",
    Print:"print",
    Redirect:"redirect",
    Select: "select",
    Back: "back",
    Close: "close", // cloase the component and clears the datastore with undefined
    Clear: "clear", // clear componenet to empty object and update the datastore with empty object
    Refresh:"refresh",
    LoadComponent: "loadcomponent",
    Custom: "custom"



    //ShowSearchForm: "showSearchForm",
    //HideSearchForm: "hideSearchForm",
    //SearchData: "searchData",
    //ResetSearch: "resetSearch",
    //RefreshData: "refresh",
    //SaveData: "saveData",
    //CancelEdit: "cancelEdit",
    //CloseAlertMessage: "closeAlertMessage",
    //ShowAlertMessage: "showAlertMessage",
    //CloseDrawer: "closeDrawer",
    //AddData: "addData"
});

export const enumFormType = Object.freeze({
    Search: "search",
    Add: "Add",
    Edit: "edit",
    Detail: "detail",
    SearchPanel: "searchPanel"
});

export const enumPageComponentType = Object.freeze({
    Form: "form",
    Grid: "grid",
    ActionBar: "actionbar",
    Repeater: "repeater",
    List:"list"
    
});

export const enumDataGridActions = Object.freeze({
    FirstPage: "firstPage",
    LastPage: "lastPage",
    NextPage: "nextPage",
    PreviousPage: "prevPage",
    SortGrid: "sortGrid",
    RowsPerPage: "rowsPerPage",
    Navigate: "navigate",
    EditRow: "editRow",
    SelectRow: "selectRow",
    DeleteRow: "deleteRow",
    AddRow: "addRow",
    Export: "export",

});

export const enumAutoCompleteOnChangeReasons = Object.freeze({
    CreateOption: "createOption",
    SelectOption: "selectOption",
    RemoveOption: "removeOption",
    Blur: "blur",
    Clear: "clear"
});

export const enumLogicalDeletionStatus = Object.freeze({
    Inserted: "inserted",
    Deleted: "deleted"
});

export const enumLoadCompOn = Object.freeze({
    Drawer: "drawer",
    PopUp: "popup",
    Section:"section"
});

export const enumFormSaveTypes = Object.freeze({
    EditRow: "editRow",
    AddData: "addData"
});

export const enumValidationTypes = Object.freeze({
    IsEmpty: "isEmpty",
    IsNotEmpty: "isNotEmpty",
    Require: "require",
    Pattern: "pattern",
    Range:"range"
});



