import React, {  useEffect,  useState } from 'react';
import {
	Box,  Alert
} from '@mui/material';

import StateStore from "../StateStore"; 
import { Store } from "../Store";

export const AlertMessage = (props) => {
	const stateStore = new StateStore("Error");
	const appStore = new Store("app");
	const [display, setDisplay] = useState(false);

	const UpdateComponent = (value) => {

		setDisplay(true);
		setTimeout(() => { setDisplay(false); appStore.Set("Error", undefined); }, 4000)
	};

	useEffect(() => {
		stateStore.Subscribe("alert", "alertmessage", UpdateComponent);

		return () => {
			stateStore.UnSubscribe("alert", "alertmessage");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const response = appStore.Get("Error");
	if (display === false)
		return "";
	return (
		<Box sx={{
			position: 'absolute', top: 75, left: 0, zIndex: 9999, width: '100%', height: 125,
			alignItems: 'center', backgroundColor: 'modelBgColor',
			justifyContent: 'center'
		}}>
			<Box sx={{
				 display:'flex',
				justifyContent: 'end'
			}}>
				<Alert severity={response?.severity} sx={{ minWidth: 500 }}>{response?.message}</Alert>
				</Box>
		</Box>
	)

}